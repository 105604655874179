import React from "react";
import SiteMetadata from "../components/SiteMetadata";
import Reveal from '../components/Reveal'
import PropTypes from "prop-types";



const TextPageTemplate = ({
  title,
  description,
  content,
  metadata,
  footerDescription,
  footerTitle
}) => {
  return (
    <>
      <SiteMetadata {...metadata} />
      <div className="container-lg mt-5 pt-5">   
        <Reveal>
          <div className="section text-center">
              <div className="row">
                <h1 className="title-2">{title}</h1>
                <p className="description">{description}</p>
              </div>
          </div>
        </Reveal>

        <Reveal>
          <section id="content" className="section-b">
            {content.title &&
              <h1 className="title-3">{content.title}</h1>
            }
            {content.description &&
              <p className="description-2 mb-5 pb-5">{content.description}</p>
            }
            <div className="row">
              {content.blocks.map((item, key) => (
                
                <div className={`${key % 2 === 0 ? '' : 'offset-xl-7'} col-xl-5 col-lg-6`}>
                  <h2 className="title-4">{item.title}</h2>
                  <p className="text">{item.text}</p>
                </div>
                
              ))}
            </div>
          </section>
        </Reveal>
        
        { (footerTitle || footerDescription) &&
          <Reveal>
            <div className="section-b">
              <div className="row">
                <h1 className="title-3">{footerTitle}</h1>
                <p className="description-2">{footerDescription}</p>
              </div>
            </div>
          </Reveal>
        }
      </div>
    </>
  );
};

TextPageTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  footerDescription: PropTypes.string,
  footerTitle: PropTypes.string,
  content: PropTypes.object,
};

export default TextPageTemplate