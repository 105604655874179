import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import TextPageTemplate from "../components/TextPageTemplate";


const MontagePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <TextPageTemplate
        title={frontmatter.title}
        description={frontmatter.description}
        footerDescription={frontmatter.footerDescription}
        footerTitle={frontmatter.footerTitle}
        content={frontmatter.content}
      />
    </Layout>
  );
};

MontagePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default MontagePage;

export const pageQuery = graphql`
  query MontagePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "montaz" } }) {
      frontmatter {
        metadata {
          title
          description
        }
        title
        description
        footerTitle
        footerDescription
        content {
          title
          description
          blocks {
            title
            text
          }
        }
      }
    }
  }
`;


